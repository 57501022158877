let teacherName = JSON.parse(localStorage.getItem("teacherName"));
const teacherData = teacherName || {};
const convertAvailabilityToArray = (availability) => {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return days.map((day, index) => ({
    date: index, // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    from: availability[`${day}StartTime`] || 0, // Default to 0 if missing
    to: availability[`${day}EndTime`] || 0, // Default to 0 if missing
  }));
};
export const dinnerTime = convertAvailabilityToArray(teacherData);
export const holidays = [];

export const data = [];

export const locations = [
  "US/Eastern",
  "US/Central",
  "US/Mountain",
  "US/Pacific",
];
