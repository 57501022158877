import React from "react";

const LoaderSpinner = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.3)", // ✅ Semi-transparent black overlay
        backdropFilter: "blur(7px)", // ✅ Blurs the background
        zIndex: 20000, // ✅ Keeps loader on top
      }}
    >
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default LoaderSpinner;
