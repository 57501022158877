import { BACKEND_URI } from "../../config/config";
import axios from "axios";
export const fetchUserMenu = async () => {
  const response = await axios.get(`${BACKEND_URI}/user-menu`);
  return response.data;
};
export const fetchAllUser = async (data) => {
  const response = await axios.post(`${BACKEND_URI}/fetch_all_users`, data);
  return response.data;
};
