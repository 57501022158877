import { React, useEffect, useState, useCallback } from "react";
import "./User.css";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Input } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BACKEND_URI } from "../../config/config";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
//import { TimePicker } from 'antd';
import TimePicker from "@semcore/ui/time-picker";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";
import { toSeconds } from "../../Convertor";
import DataTable, { createTheme } from "react-data-table-component";
import { BiLogOut } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { CSVLink } from "react-csv";
import { secondsToHmsssss } from "../../Convertor";
import Placeholder from "react-bootstrap/Placeholder";
import admin_pic from "../../Assets/94592.png";
import { set } from "date-fns/esm";
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchUserMenu, fetchAllUser } from "./user-api-functions";
import { NewUser } from "./create-user";
import LoaderSpinner from "../loader";
import { convertSecondsToTime } from "../../Convertor";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStylesAgency(name, personNameEnter, theme) {
  return {
    fontWeight:
      personNameEnter.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const convertWeekdayTimes = (user) => {
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  weekdays.forEach((day) => {
    const startKey = `${day}StartTime`;
    const endKey = `${day}EndTime`;

    if (user[startKey] !== null && user[endKey] !== null) {
      user[startKey] = convertSecondsToTime(user[startKey]);
      user[endKey] = convertSecondsToTime(user[endKey]);
    }
  });

  return user;
};

function convertUserTimes(users) {
  return users.map((user) => convertWeekdayTimes(user));
}
function User() {
  const [status, setStatus] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [selectLanguages, setSelectLanguages] = useState([]);
  const [selectPrograms, setSelectProgram] = useState([]);
  const [selectSchools, setSelectSchool] = useState([]);
  const [selectGrades, setSelectGrades] = useState([]);
  const [selectSubjects, setSelectSubjects] = useState([]);
  const [newRole, setNewRole] = useState([]);
  const [agencyData, setAgencyData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [schoolsData, setSchoolData] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [timeZoneGet, setTimeZoneGet] = useState([]);
  const [languageGet, setLanguageGet] = useState([]);
  const [activeShow, setActiveShow] = useState("true");
  const [getAgencyDataFalse, setGetAgencyDataFalse] = useState([]);
  const [loadingActive, setLoadingActive] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [scheduleTableId, setscheduleTableId] = useState("");
  const [search, setSearch] = useState("");
  const [searchInactive, setSearchInactive] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [filterSearchInactive, setFilterSearchInactive] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sckelton, setSckelton] = useState(false);
  const [modal, setModal] = useState(false);
  const [userFile, setUserFile] = useState(null);
  // ...........Show Password functon ....................//
  // const [imageSource, setImageSource] = useState();

  const token = localStorage.getItem("jwtToken");
  const location = useLocation();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const selectedRole = new URLSearchParams(location.search).get("selectedRole");
  const [userByRoleShow, setUserByRoleShow] = useState(
    selectedRole || "Teacher"
  );
  const { mutate, isPending, error } = useMutation({
    mutationKey: ["fetch-all-users"],
    mutationFn: fetchAllUser,
    // staleTime: 3 * 60 * 1000,
    onSuccess: (data) => {
      const formattedData = convertUserTimes(data);
      // console.log("formatted data", formattedData);
      setUsersData(formattedData);
    },
  });
  useQuery({
    queryKey: ["users-menu"], // Unique key for caching
    queryFn: fetchUserMenu,
    staleTime: 5 * 60 * 1000,
    select: useCallback((data) => {
      setNewRole(data.roles);
      setAgencyData(data.agencies);
      setProgramData(data.programs);
      setSchoolData(data.schools);
      setGradeData(data.grades);
      setSubjectData(data.subjects);
      setTimeZoneGet(data.timezones);
      setLanguageGet(data.languages);
    }, []),
  });
  // console.log("schoolsData ", schoolsData);

  const columns = [
    {
      name: (
        <span style={{ fontSize: "15px", fontWeight: "600" }}>Full Name</span>
      ),
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      grow: 2,
    },
    // {
    //   name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Profile Dp</span>,
    //   cell: row => (
    //     <>
    //       {row.image == '' || !row.image ? (
    //         <img
    //           src={admin_pic}// Provide the URL to your dummy image
    //           alt="Dp"
    //           style={{ width: "40px", height: "40px" }} // Adjust the width and height as needed
    //         />
    //       ) : (
    //         <img
    //           src={`${BACKEND_URI}/images/${row.image}`} // Assuming the images are served from the /uploads folder on the backend
    //           alt="Dp"
    //           style={{ width: "45px", height: "45px" }} // Adjust the width and height as needed
    //         />

    //       )}
    //     </>
    //   ),
    //   sortable: true,
    //   grow: 1
    // },
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Email</span>,
      selector: (row) => row.email,
      sortable: true,
      grow: 2,
    },

    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Mobile</span>,
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Agency</span>,
      selector: (row) =>
        row.personNameEnter.length > 0 ? (
          row.personNameEnter.join(",")
        ) : (
          <div style={{ fontSize: "18px" }}>-</div>
        ),
      sortable: true,
      grow: 2,
    },
    {
      name: (
        <span style={{ fontSize: "15px", fontWeight: "600" }}>Program</span>
      ),
      selector: (row) =>
        row.selectProgramsEnter.length > 0 ? (
          row.selectProgramsEnter.join(",")
        ) : (
          <div style={{ fontSize: "18px" }}>-</div>
        ),
      sortable: true,
      grow: 2,
    },
    {
      name: (
        <span style={{ fontSize: "15px", fontWeight: "600" }}>Location</span>
      ),
      selector: (row) => row.address,
      sortable: true,
      grow: 1,
    },
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Status</span>,
      cell: (row) =>
        row.activeStatus == "false" ? (
          <button className="btn btn-Inactive" size="sm">
            Inactive
          </button>
        ) : (
          <button className="btn btn-active" size="sm">
            active
          </button>
        ),
    },
    {
      name: (
        <span style={{ fontSize: "15px", fontWeight: "600" }}>Control</span>
      ),
      cell: (row) => (
        <>
          {activeShow == "false" ? (
            <>
              <button
                className="btn btn-xs btn-warnings me-2 mt-1"
                style={{ paddibg: "0" }}
                title="Update"
                onClick={(e) => UserDataDelete(row._id, row.activeStatus)}
              >
                <BiLogOut style={{ color: "white" }} size={20}></BiLogOut>
              </button>
              <button
                className="btn btn-xxs btn-dangers mt-1 hidden-data"
                style={{ color: "white" }}
                title="Delete"
                onClick={() => userDeletePermanent(row._id)}
              >
                <MdDelete size={20} />
              </button>
            </>
          ) : (
            <>
              <Link
                to={`/sidebar/view_single_User_Data/${row._id}`}
                style={{ textDecoration: "none" }}
              >
                <button
                  className="btn btn-xs btn-infoss me-2 mt-1"
                  style={{ paddibg: "0" }}
                  title="View"
                >
                  <i className="fa-solid fa-eye" style={{ color: "white" }}></i>
                </button>
              </Link>
              <Link
                to={`/sidebar/update_single_user_data/${row._id}`}
                style={{ textDecoration: "none" }}
              >
                <button
                  className="btn btn-xs btn-warnings me-2 mt-1"
                  style={{ paddibg: "0" }}
                  title="Update"
                >
                  <i
                    className="fa-solid fa-pencil"
                    style={{ color: "white" }}
                  ></i>
                </button>
              </Link>
              <button
                className="btn btn-xxs btn-dangers mt-1 hidden-data"
                title="Delete"
                onClick={(e) => UserDataDelete(row._id, row.activeStatus)}
              >
                <i className="fa-solid fa-xmark" style={{ color: "white" }}></i>
              </button>
            </>
          )}
        </>
      ),
    },
  ];
  createTheme("solarized", {
    background: {
      default: "#c0dfdf",
    },
    text: {
      primary: "#08272a",
      secondary: "#08272a",
    },
  });
  const handleUpload = async () => {
    if (!userFile) {
      toast.error("Please Upload File");
      return;
    }
    setDeleteLoading(true);
    const formData = new FormData();
    formData.append("file", userFile);
    try {
      const response = await axios.post(`${BACKEND_URI}/bulk_User`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response) {
        setDeleteLoading(false);
        setUserFile(false);
        setModal(false);
      }
      console.log("Upload successful:", response.data);
    } catch (error) {
      setDeleteLoading(false);
      setModal(false);
      console.error("Error uploading file:", error);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeOne = (event) => {
    const {
      target: { value },
    } = event;
    console.log("language", event);

    setSelectLanguages(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeTwo = (event) => {
    const {
      target: { value },
    } = event;
    console.log("program", event);
    setSelectProgram(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeThree = (event) => {
    const {
      target: { value },
    } = event;
    console.log("school", event);

    setSelectSchool(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeFour = (event) => {
    const {
      target: { value },
    } = event;
    console.log("grades", event);

    setSelectGrades(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeFive = (event) => {
    const {
      target: { value },
    } = event;
    console.log("subjects", event);

    setSelectSubjects(typeof value === "string" ? value.split(",") : value);
  };
  const userByNameShowHandle = (e) => {
    setUserByRoleShow(e.target.value);
  };
  const changeAgenciews = () => {
    setStatus(!status);
  };

  useEffect(() => {
    mutate({ role: userByRoleShow, active: activeShow });
  }, [userByRoleShow, activeShow, selectedRole]);
  const getUserAllDatas = async () => {
    try {
      setTableLoading(true);
      setSckelton(true);

      await axios
        .post(`${BACKEND_URI}/fetch_all_users`, {}, { headers })
        .then((response) => {
          console.log("fetch ", response);
          let arry = [];
          let arryfalse = [];
          let csvInactiveArry = [];
          let csvActiveArray = [];

          for (var i = 0; i < response.data.length; i++) {
            let statusCheck = response.data[i].activeStatus;
            // let roleCheck = response.data[i].role
            let len = response.data[i].role.length;

            // let roleCheck
            for (let j = 0; j < len; j++) {
              let roleCheck = response.data[i].role[j];

              // console.log("--- all Roles---", roleCheck)
              if (activeShow == "true") {
                if (statusCheck == "false" && roleCheck == userByRoleShow) {
                  let address = response.data[i].address;
                  let role = response.data[i].role;
                  let firstName = response.data[i].firstName;
                  let lastName = response.data[i].lastName;
                  let personNameEnter = response.data[i].personNameEnter;
                  let email = response.data[i].email;
                  let mobileNumber = response.data[i].mobileNumber;
                  let gender = response.data[i].gender;
                  let dp = response.data[i].image;
                  let timeZone = response.data[i].timeZone;
                  let selectProgramsEnter =
                    response.data[i].selectProgramsEnter;
                  let selectSchoolsEnter = response.data[i].selectSchoolsEnter;
                  let selectGradesEnter = response.data[i].selectGradesEnter;
                  let selectSubjectsEnter =
                    response.data[i].selectSubjectsEnter;
                  let selectLanguagesEnter =
                    response.data[i].selectLanguagesEnter;
                  let mondayStartTime = secondsToHmsssss(
                    response.data[i].mondayStartTime
                  );
                  const [hourString, minute] = mondayStartTime.split(":");
                  const hour = +hourString % 24;
                  let monday_time_Chnage =
                    (hour % 12 || 12) +
                    ":" +
                    minute +
                    (hour < 12 ? " AM" : " PM");
                  let mondayEndTime = secondsToHmsssss(
                    response.data[i].mondayEndTime
                  );
                  const [hourStrings, minutes] = mondayEndTime.split(":");
                  const hours = +hourStrings % 24;
                  let monday_End_time_Chnage =
                    (hours % 12 || 12) +
                    ":" +
                    minutes +
                    (hours < 12 ? " AM" : " PM");
                  let tuesdayStartTime = secondsToHmsssss(
                    response.data[i].tuesdayStartTime
                  );
                  const [
                    hourStringTuesday_start_time,
                    minuteTuesday_start_time,
                  ] = tuesdayStartTime.split(":");
                  const hourTuesday_start_time =
                    +hourStringTuesday_start_time % 24;
                  let Tuesday_start_time_Chnage =
                    (hourTuesday_start_time % 12 || 12) +
                    ":" +
                    minuteTuesday_start_time +
                    (hourTuesday_start_time < 12 ? " AM" : " PM");
                  let tuesdayEndTime = secondsToHmsssss(
                    response.data[i].tuesdayEndTime
                  );
                  const [hourStringTuesday_End_time, minuteTuesday_End_time] =
                    tuesdayEndTime.split(":");
                  const hourTuesday_End_time = +hourStringTuesday_End_time % 24;
                  let Tuesday_End_time_Chnage =
                    (hourTuesday_End_time % 12 || 12) +
                    ":" +
                    minuteTuesday_End_time +
                    (hourTuesday_End_time < 12 ? " AM" : " PM");
                  let wednesdayStartTime = secondsToHmsssss(
                    response.data[i].wednesdayStartTime
                  );
                  const [
                    hourStringWednesday_start_Time,
                    minuteWednesday_start_Time,
                  ] = wednesdayStartTime.split(":");
                  const hourWednesday_start_Time =
                    +hourStringWednesday_start_Time % 24;
                  let Wednesday_start_Time_Chnage =
                    (hourWednesday_start_Time % 12 || 12) +
                    ":" +
                    minuteWednesday_start_Time +
                    (hourWednesday_start_Time < 12 ? " AM" : " PM");
                  let wednesdayEndTime = secondsToHmsssss(
                    response.data[i].wednesdayEndTime
                  );
                  const [
                    hourStringWednesday_End_Time,
                    minuteWednesday_End_Time,
                  ] = wednesdayEndTime.split(":");
                  const hourWednesday_End_Time =
                    +hourStringWednesday_End_Time % 24;
                  let Wednesday_End_Time_Chnage =
                    (hourWednesday_End_Time % 12 || 12) +
                    ":" +
                    minuteWednesday_End_Time +
                    (hourWednesday_End_Time < 12 ? " AM" : " PM");
                  let thursdayStartTime = secondsToHmsssss(
                    response.data[i].thursdayStartTime
                  );
                  const [
                    hourStringThursday_Start_Time,
                    minuteThursday_Start_Time,
                  ] = thursdayStartTime.split(":");
                  const hourThursday_Start_Time =
                    +hourStringThursday_Start_Time % 24;
                  let Thursday_Start_Time_Chnage =
                    (hourThursday_Start_Time % 12 || 12) +
                    ":" +
                    minuteThursday_Start_Time +
                    (hourThursday_Start_Time < 12 ? " AM" : " PM");
                  let thursdayEndTime = secondsToHmsssss(
                    response.data[i].thursdayEndTime
                  );
                  const [hourStringThursday_End_Time, minuteThursday_End_Time] =
                    thursdayEndTime.split(":");
                  const hourThursday_End_Time =
                    +hourStringThursday_End_Time % 24;
                  let Thursday_End_Time_Chnage =
                    (hourThursday_End_Time % 12 || 12) +
                    ":" +
                    minuteThursday_End_Time +
                    (hourThursday_End_Time < 12 ? " AM" : " PM");
                  let fridayStartTime = secondsToHmsssss(
                    response.data[i].fridayStartTime
                  );
                  const [hourStringFriday_Start_Time, minuteFriday_Start_Time] =
                    fridayStartTime.split(":");
                  const hourFriday_Start_Time =
                    +hourStringFriday_Start_Time % 24;
                  let Friday_Start_Time_Chnage =
                    (hourFriday_Start_Time % 12 || 12) +
                    ":" +
                    minuteFriday_Start_Time +
                    (hourFriday_Start_Time < 12 ? " AM" : " PM");
                  let fridayEndTime = secondsToHmsssss(
                    response.data[i].fridayEndTime
                  );
                  const [hourStringFriday_End_Time, minuteFriday_End_Time] =
                    fridayEndTime.split(":");
                  const hourFriday_End_Time = +hourStringFriday_End_Time % 24;
                  let Friday_End_Time_Chnage =
                    (hourFriday_End_Time % 12 || 12) +
                    ":" +
                    minuteFriday_End_Time +
                    (hourFriday_End_Time < 12 ? " AM" : " PM");
                  let saturdayStartTime = secondsToHmsssss(
                    response.data[i].saturdayStartTime
                  );
                  const [
                    hourStringSaturday_Start_Time,
                    minuteSaturday_Start_Time,
                  ] = saturdayStartTime.split(":");
                  const hourSaturday_Start_Time =
                    +hourStringSaturday_Start_Time % 24;
                  let Saturday_Start_Time_Chnage =
                    (hourSaturday_Start_Time % 12 || 12) +
                    ":" +
                    minuteSaturday_Start_Time +
                    (hourSaturday_Start_Time < 12 ? " AM" : " PM");
                  let saturdayEndTime = secondsToHmsssss(
                    response.data[i].saturdayEndTime
                  );
                  const [hourStringSaturday_End_Time, minuteSaturday_End_Time] =
                    saturdayEndTime.split(":");
                  const hourSaturday_End_Time =
                    +hourStringSaturday_End_Time % 24;
                  let Saturday_End_Time_Chnage =
                    (hourSaturday_End_Time % 12 || 12) +
                    ":" +
                    minuteSaturday_End_Time +
                    (hourSaturday_End_Time < 12 ? " AM" : " PM");
                  let sundayStartTime = secondsToHmsssss(
                    response.data[i].sundayStartTime
                  );
                  const [hourStringSunday_Start_Time, minuteSunday_Start_Time] =
                    sundayStartTime.split(":");
                  const hourSunday_Start_Time =
                    +hourStringSunday_Start_Time % 24;
                  let Sunday_Start_Time_Chnage =
                    (hourSunday_Start_Time % 12 || 12) +
                    ":" +
                    minuteSunday_Start_Time +
                    (hourSunday_Start_Time < 12 ? " AM" : " PM");
                  let sundayEndTime = secondsToHmsssss(
                    response.data[i].sundayEndTime
                  );
                  const [hourStringSunday_End_Time, minuteSunday_End_Time] =
                    sundayEndTime.split(":");
                  const hourSunday_End_Time = +hourStringSunday_End_Time % 24;
                  let Sunday_End_Time_Chnage =
                    (hourSunday_End_Time % 12 || 12) +
                    ":" +
                    minuteSunday_End_Time +
                    (hourSunday_End_Time < 12 ? " AM" : " PM");
                  csvInactiveArry.push({
                    address: address,
                    role: role,
                    firstName: firstName,
                    lastName: lastName,
                    personNameEnter: personNameEnter,
                    email: email,
                    mobileNumber: mobileNumber,
                    gender: gender,
                    timeZone: timeZone,
                    selectProgramsEnter: selectProgramsEnter,
                    selectSchoolsEnter: selectSchoolsEnter,
                    selectGradesEnter: selectGradesEnter,
                    selectSubjectsEnter: selectSubjectsEnter,
                    selectSubjectsEnter: selectSubjectsEnter,
                    selectLanguagesEnter: selectLanguagesEnter,
                    mondayStartTime: monday_time_Chnage,
                    mondayEndTime: monday_End_time_Chnage,
                    tuesdayStartTime: Tuesday_start_time_Chnage,
                    tuesdayEndTime: Tuesday_End_time_Chnage,
                    wednesdayStartTime: Wednesday_start_Time_Chnage,
                    thursdayStartTime: Thursday_Start_Time_Chnage,
                    thursdayEndTime: Thursday_End_Time_Chnage,
                    fridayStartTime: Friday_Start_Time_Chnage,
                    saturdayStartTime: Saturday_Start_Time_Chnage,
                    saturdayEndTime: Saturday_End_Time_Chnage,
                    sundayStartTime: Sunday_Start_Time_Chnage,
                    sundayEndTime: Sunday_End_Time_Chnage,
                    wednesdayEndTime: Wednesday_End_Time_Chnage,
                    fridayEndTime: Friday_End_Time_Chnage,
                  });
                  arryfalse.push(response.data[i]);
                }
              } else {
                if (statusCheck == "true" && roleCheck == userByRoleShow) {
                  let address = response.data[i].address;
                  let role = response.data[i].role;
                  let firstName = response.data[i].firstName;
                  let lastName = response.data[i].lastName;

                  let personNameEnter = response.data[i].personNameEnter;
                  let email = response.data[i].email;
                  let mobileNumber = response.data[i].mobileNumber;
                  let gender = response.data[i].gender;
                  let timeZone = response.data[i].timeZone;
                  let selectProgramsEnter =
                    response.data[i].selectProgramsEnter;
                  let selectSchoolsEnter = response.data[i].selectSchoolsEnter;
                  let selectGradesEnter = response.data[i].selectGradesEnter;
                  let selectSubjectsEnter =
                    response.data[i].selectSubjectsEnter;
                  let selectLanguagesEnter =
                    response.data[i].selectLanguagesEnter;
                  let mondayStartTime = secondsToHmsssss(
                    response.data[i].mondayStartTime
                  );
                  const [hourString, minute] = mondayStartTime.split(":");
                  const hour = +hourString % 24;
                  let monday_time_Chnage =
                    (hour % 12 || 12) +
                    ":" +
                    minute +
                    (hour < 12 ? " AM" : " PM");
                  let mondayEndTime = secondsToHmsssss(
                    response.data[i].mondayEndTime
                  );
                  const [hourStrings, minutes] = mondayEndTime.split(":");
                  const hours = +hourStrings % 24;
                  let monday_End_time_Chnage =
                    (hours % 12 || 12) +
                    ":" +
                    minutes +
                    (hours < 12 ? " AM" : " PM");
                  let tuesdayStartTime = secondsToHmsssss(
                    response.data[i].tuesdayStartTime
                  );
                  const [
                    hourStringTuesday_start_time,
                    minuteTuesday_start_time,
                  ] = tuesdayStartTime.split(":");
                  const hourTuesday_start_time =
                    +hourStringTuesday_start_time % 24;
                  let Tuesday_start_time_Chnage =
                    (hourTuesday_start_time % 12 || 12) +
                    ":" +
                    minuteTuesday_start_time +
                    (hourTuesday_start_time < 12 ? " AM" : " PM");
                  let tuesdayEndTime = secondsToHmsssss(
                    response.data[i].tuesdayEndTime
                  );
                  const [hourStringTuesday_End_time, minuteTuesday_End_time] =
                    tuesdayEndTime.split(":");
                  const hourTuesday_End_time = +hourStringTuesday_End_time % 24;
                  let Tuesday_End_time_Chnage =
                    (hourTuesday_End_time % 12 || 12) +
                    ":" +
                    minuteTuesday_End_time +
                    (hourTuesday_End_time < 12 ? " AM" : " PM");
                  let wednesdayStartTime = secondsToHmsssss(
                    response.data[i].wednesdayStartTime
                  );
                  const [
                    hourStringWednesday_start_Time,
                    minuteWednesday_start_Time,
                  ] = wednesdayStartTime.split(":");
                  const hourWednesday_start_Time =
                    +hourStringWednesday_start_Time % 24;
                  let Wednesday_start_Time_Chnage =
                    (hourWednesday_start_Time % 12 || 12) +
                    ":" +
                    minuteWednesday_start_Time +
                    (hourWednesday_start_Time < 12 ? " AM" : " PM");
                  let wednesdayEndTime = secondsToHmsssss(
                    response.data[i].wednesdayEndTime
                  );
                  const [
                    hourStringWednesday_End_Time,
                    minuteWednesday_End_Time,
                  ] = wednesdayEndTime.split(":");
                  const hourWednesday_End_Time =
                    +hourStringWednesday_End_Time % 24;
                  let Wednesday_End_Time_Chnage =
                    (hourWednesday_End_Time % 12 || 12) +
                    ":" +
                    minuteWednesday_End_Time +
                    (hourWednesday_End_Time < 12 ? " AM" : " PM");
                  let thursdayStartTime = secondsToHmsssss(
                    response.data[i].thursdayStartTime
                  );
                  const [
                    hourStringThursday_Start_Time,
                    minuteThursday_Start_Time,
                  ] = thursdayStartTime.split(":");
                  const hourThursday_Start_Time =
                    +hourStringThursday_Start_Time % 24;
                  let Thursday_Start_Time_Chnage =
                    (hourThursday_Start_Time % 12 || 12) +
                    ":" +
                    minuteThursday_Start_Time +
                    (hourThursday_Start_Time < 12 ? " AM" : " PM");
                  let thursdayEndTime = secondsToHmsssss(
                    response.data[i].thursdayEndTime
                  );
                  const [hourStringThursday_End_Time, minuteThursday_End_Time] =
                    thursdayEndTime.split(":");
                  const hourThursday_End_Time =
                    +hourStringThursday_End_Time % 24;
                  let Thursday_End_Time_Chnage =
                    (hourThursday_End_Time % 12 || 12) +
                    ":" +
                    minuteThursday_End_Time +
                    (hourThursday_End_Time < 12 ? " AM" : " PM");
                  let fridayStartTime = secondsToHmsssss(
                    response.data[i].fridayStartTime
                  );
                  const [hourStringFriday_Start_Time, minuteFriday_Start_Time] =
                    fridayStartTime.split(":");
                  const hourFriday_Start_Time =
                    +hourStringFriday_Start_Time % 24;
                  let Friday_Start_Time_Chnage =
                    (hourFriday_Start_Time % 12 || 12) +
                    ":" +
                    minuteFriday_Start_Time +
                    (hourFriday_Start_Time < 12 ? " AM" : " PM");
                  let fridayEndTime = secondsToHmsssss(
                    response.data[i].fridayEndTime
                  );
                  const [hourStringFriday_End_Time, minuteFriday_End_Time] =
                    fridayEndTime.split(":");
                  const hourFriday_End_Time = +hourStringFriday_End_Time % 24;
                  let Friday_End_Time_Chnage =
                    (hourFriday_End_Time % 12 || 12) +
                    ":" +
                    minuteFriday_End_Time +
                    (hourFriday_End_Time < 12 ? " AM" : " PM");
                  let saturdayStartTime = secondsToHmsssss(
                    response.data[i].saturdayStartTime
                  );
                  const [
                    hourStringSaturday_Start_Time,
                    minuteSaturday_Start_Time,
                  ] = saturdayStartTime.split(":");
                  const hourSaturday_Start_Time =
                    +hourStringSaturday_Start_Time % 24;
                  let Saturday_Start_Time_Chnage =
                    (hourSaturday_Start_Time % 12 || 12) +
                    ":" +
                    minuteSaturday_Start_Time +
                    (hourSaturday_Start_Time < 12 ? " AM" : " PM");
                  let saturdayEndTime = secondsToHmsssss(
                    response.data[i].saturdayEndTime
                  );
                  const [hourStringSaturday_End_Time, minuteSaturday_End_Time] =
                    saturdayEndTime.split(":");
                  const hourSaturday_End_Time =
                    +hourStringSaturday_End_Time % 24;
                  let Saturday_End_Time_Chnage =
                    (hourSaturday_End_Time % 12 || 12) +
                    ":" +
                    minuteSaturday_End_Time +
                    (hourSaturday_End_Time < 12 ? " AM" : " PM");
                  let sundayStartTime = secondsToHmsssss(
                    response.data[i].sundayStartTime
                  );
                  const [hourStringSunday_Start_Time, minuteSunday_Start_Time] =
                    sundayStartTime.split(":");
                  const hourSunday_Start_Time =
                    +hourStringSunday_Start_Time % 24;
                  let Sunday_Start_Time_Chnage =
                    (hourSunday_Start_Time % 12 || 12) +
                    ":" +
                    minuteSunday_Start_Time +
                    (hourSunday_Start_Time < 12 ? " AM" : " PM");
                  let sundayEndTime = secondsToHmsssss(
                    response.data[i].sundayEndTime
                  );
                  const [hourStringSunday_End_Time, minuteSunday_End_Time] =
                    sundayEndTime.split(":");
                  const hourSunday_End_Time = +hourStringSunday_End_Time % 24;
                  let Sunday_End_Time_Chnage =
                    (hourSunday_End_Time % 12 || 12) +
                    ":" +
                    minuteSunday_End_Time +
                    (hourSunday_End_Time < 12 ? " AM" : " PM");
                  csvActiveArray.push({
                    address: address,
                    role: role,
                    firstName: firstName,
                    lastName: lastName,
                    personNameEnter: personNameEnter,
                    email: email,
                    mobileNumber: mobileNumber,
                    gender: gender,
                    timeZone: timeZone,
                    selectProgramsEnter: selectProgramsEnter,
                    selectSchoolsEnter: selectSchoolsEnter,
                    selectGradesEnter: selectGradesEnter,
                    selectSubjectsEnter: selectSubjectsEnter,
                    selectSubjectsEnter: selectSubjectsEnter,
                    selectLanguagesEnter: selectLanguagesEnter,
                    mondayStartTime: monday_time_Chnage,
                    mondayEndTime: monday_End_time_Chnage,
                    tuesdayStartTime: Tuesday_start_time_Chnage,
                    tuesdayEndTime: Tuesday_End_time_Chnage,
                    wednesdayStartTime: Wednesday_start_Time_Chnage,
                    thursdayStartTime: Thursday_Start_Time_Chnage,
                    thursdayEndTime: Thursday_End_Time_Chnage,
                    fridayStartTime: Friday_Start_Time_Chnage,
                    saturdayStartTime: Saturday_Start_Time_Chnage,
                    saturdayEndTime: Saturday_End_Time_Chnage,
                    sundayStartTime: Sunday_Start_Time_Chnage,
                    sundayEndTime: Sunday_End_Time_Chnage,
                    wednesdayEndTime: Wednesday_End_Time_Chnage,
                    fridayEndTime: Friday_End_Time_Chnage,
                  });
                  arry.push(response.data[i]);
                }
              }
            }
          }

          setUsersData(arry);

          setTableLoading(false);
          setSckelton(false);
        });
    } catch (e) {
      console.log("e", e);
      setSckelton(false);
    }
  };

  const csvHeaders = [
    {
      label: "Role",
      key: "role",
    },
    {
      label: "FirstName",
      key: "firstName",
    },
    {
      label: "LastName",
      key: "lastName",
    },
    {
      label: "Agency",
      key: "personNameEnter",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "PhoneNo",
      key: "mobileNumber",
    },
    {
      label: "Address",
      key: "address",
    },
    {
      label: "Gender",
      key: "gender",
    },
    {
      label: "timeZone",
      key: "timeZone",
    },
    {
      label: "Program",
      key: "selectProgramsEnter",
    },
    {
      label: "School",
      key: "selectSchoolsEnter",
    },
    {
      label: "Grade",
      key: "selectGradesEnter",
    },
    {
      label: "Subject",
      key: "selectSubjectsEnter",
    },
    {
      label: "language",
      key: "selectLanguagesEnter",
    },
    {
      label: "Monday_Start_Time",
      key: "mondayStartTime",
    },
    {
      label: "Monday_End_Time",
      key: "mondayEndTime",
    },
    {
      label: "Tuesday_Start_Time",
      key: "tuesdayStartTime",
    },
    {
      label: "Tuesday_End_Time",
      key: "tuesdayEndTime",
    },
    {
      label: "Wednesday_Start_Time",
      key: "wednesdayStartTime",
    },
    {
      label: "Wednesday_End_Time",
      key: "wednesdayEndTime",
    },
    {
      label: "Thursday_Start_Time",
      key: "thursdayStartTime",
    },
    {
      label: "Thursday_End_Time",
      key: "thursdayEndTime",
    },
    {
      label: "Friday_Start_Time",
      key: "fridayStartTime",
    },
    {
      label: "Friday_End_Time",
      key: "fridayEndTime",
    },
    {
      label: "Saturday_Start_Time",
      key: "saturdayStartTime",
    },
    {
      label: "Saturday_End_Time",
      key: "saturdayEndTime",
    },
    {
      label: "Sunday_Start_Time",
      key: "sundayStartTime",
    },
    {
      label: "Sunday_End_Time",
      key: "sundayEndTime",
    },
  ];

  const csvLink = {
    filename: "Tutor.csv",
    headers: csvHeaders,
    data: usersData,
  };

  const activeHandle = (e) => {
    console.log("active=", e.target.value);
    setActiveShow(e.target.value);
  };

  const userDeletePermanent = async (ids) => {
    try {
      setscheduleTableId(ids);
      setModalShow(true);
    } catch (e) {
      console.log("e", e);
    }
  };

  // delete permanent user
  const handleDataDelete = async () => {
    try {
      setDeleteLoading(true);
      let data = new Date();
      let dateTime = data.toDateString();
      let stuent_data = JSON.parse(localStorage.getItem("studentNest"));
      let First_Name = stuent_data.firstName;
      let Last_Name = stuent_data.lastName;
      let status;
      await axios
        .delete(`${BACKEND_URI}/delete_permanently_user/${scheduleTableId}`, {
          headers,
        })
        .then(async (resdelete) => {
          getUserAllDatas();
          setModalShow(false);
          setDeleteLoading(false);
          toast.success("User Deleted Parmanently");
          if (resdelete) {
            await axios
              .get(`${BACKEND_URI}/user_single_data_find/${scheduleTableId}`, {
                headers,
              })
              .then((resData) => {
                let userName =
                  resData.data.firstName + " " + resData.data.lastName;

                status = `${userName} has been Permanently Deleted by ${First_Name} ${Last_Name}`;
              });
            if (resdelete) {
              await axios
                .post(`${BACKEND_URI}/activity_Log`, {
                  First_Name,
                  Last_Name,
                  dateTime,
                  status,
                })
                .then((resactivty) => {});
            }
          }
        });
    } catch (e) {
      setDeleteLoading(false);
      console.log("e", e);
      toast.error("Something Went Wrong! Please Try Again");
    }
  };
  // user Active data inactive
  const UserDataDelete = async (id, status) => {
    let activeStatus = "";
    if (status === "true") {
      setLoadingActive(true);
      activeStatus = false;
      let data = new Date();
      let dateTime = data.toDateString();
      let stuent_data = JSON.parse(localStorage.getItem("studentNest"));
      let First_Name = stuent_data.firstName;
      let Last_Name = stuent_data.lastName;
      let status;
      await axios
        .put(
          `${BACKEND_URI}/user_Single_Data_Delete/${id}`,
          {
            activeStatus,
          },
          { headers }
        )
        .then(async (res) => {
          if (res) toast.success("Tutor Inactive successfully");
          // setLoadingActive(false);
          // getUserAllDatas();
          mutate({ role: userByRoleShow, active: activeShow });
          await axios
            .get(`${BACKEND_URI}/user_single_data_find/${id}`, { headers })
            .then((resData) => {
              let userName =
                resData.data.firstName + " " + resData.data.lastName;
              status = `${userName} has been Inactive by ${First_Name} ${Last_Name}`;
            });
          if (res) {
            await axios
              .post(`${BACKEND_URI}/activity_Log`, {
                First_Name,
                Last_Name,
                dateTime,
                status,
              })
              .then((resactivty) => {});
          }
        });
      await axios
        .delete(`${BACKEND_URI}/delete_Student_All_Data/${id}`, { headers })
        .then((resDelete) => {});
    } else {
      setLoadingActive(true);
      activeStatus = true;
      let data = new Date();
      let dateTime = data.toDateString();
      let stuent_data = JSON.parse(localStorage.getItem("studentNest"));
      let First_Name = stuent_data.firstName;
      let Last_Name = stuent_data.lastName;
      let status;
      await axios
        .put(
          `${BACKEND_URI}/user_Single_Data_Delete/${id}`,
          {
            activeStatus,
          },
          { headers }
        )
        .then(async (res) => {
          if (res) toast.success("Tutor Active successfully");
          // getUserAllDatas();
          mutate({ role: userByRoleShow, active: activeShow });
          await axios
            .get(`${BACKEND_URI}/user_single_data_find/${id}`, { headers })
            .then((resData) => {
              let userName =
                resData.data.firstName + " " + resData.data.lastName;
              status = `${userName} has been active by ${First_Name} ${Last_Name}`;
            });
          if (res) {
            await axios
              .post(`${BACKEND_URI}/activity_Log`, {
                First_Name,
                Last_Name,
                dateTime,
                status,
              })
              .then((resactivty) => {});
          }
        });
      await axios
        .delete(`${BACKEND_URI}/delete_Student_All_Data/${id}`, { headers })
        .then((resDelete) => {});
    }
  };
  //  Search by Input
  useEffect(() => {
    if (search.trim() === "") {
      setFilteredUsers(usersData); // Reset to original users when search is empty
    } else {
      setFilteredUsers(
        usersData.filter((user) =>
          user.firstName.toLowerCase().match(search.toLowerCase())
        )
      );
    }
  }, [search]);

  useEffect(() => {
    const result = getAgencyDataFalse.filter((value) => {
      return value.firstName.toLowerCase().match(searchInactive.toLowerCase());
    });
    setFilterSearchInactive(result);
  }, [searchInactive]);
  const handleCloseModel = () => {
    setStatus(false);
    mutate({ role: userByRoleShow, active: activeShow });
  };
  // Filter All configuration
  const filterValue = async () => {
    try {
      if (
        !personName.length &&
        !selectPrograms.length &&
        !selectLanguages.length &&
        !selectSchools.length &&
        !selectGrades.length &&
        !selectSubjects.length
      ) {
        // Show an error message or handle the validation as needed
        toast.error("Please Select Value from Users Menu");
      } else {
        setTableLoading(true);
        await axios
          .get(
            `${BACKEND_URI}/Data_User_Filter/role=${userByRoleShow}&activeStatus=${activeShow}&personName=${personName}&selectPrograms=${selectPrograms}&selectLanguages=${selectLanguages}&selectSchools=${selectSchools}&selectGrades=${selectGrades}&selectSubjects=${selectSubjects}`,
            { headers }
          )
          .then((response) => {
            const formattedData = convertUserTimes(response.data);
            setUsersData(formattedData);
            setTableLoading(false);

            // let arry = [];
            // let arryfalse = [];

            // for (var i = 0; i < filterData.data.length; i++) {
            //   let statusCheck = filterData.data[i].activeStatus;
            //   let roleCheck = filterData.data[i].role;
            //   if (activeShow == "true") {
            //     if (statusCheck == "false" && roleCheck == userByRoleShow) {
            //       arryfalse.push(filterData.data[i]);
            //     }
            //   } else {
            //     if (statusCheck == "true" && roleCheck == userByRoleShow) {
            //       // console.log("filterData",filterData.data[i]);
            //       arry.push(filterData.data[i]);
            //     }
            //   }
            // }
            // console.log(arryfalse,"arry ==>",arry)
            // setTableLoading(false);
            // setUserActive(arryfalse.length);
            // setGetAgencyDataFalse(arryfalse);
            // setFilterSearchInactive(arryfalse);
            // setUserInactive(arry.length);
            // setUserAllData(arry);
          });
      }
    } catch (e) {
      setTableLoading(false);
      console.log("e", e);
    }
  };

  // useEffect(() => {
  //   getUserAllDatas();
  // }, [userByRoleShow]);
  // useEffect(() => {
  //   if (selectedRole == userByRoleShow || selectedRole == null) {
  //     getUserAllDatas();
  //   }
  // }, []);
  // useEffect(() => {
  //   getUserAllDatas();
  // }, [activeShow]);

  function getRoleValue(title) {
    switch (title) {
      case "Student":
        return "Student";
      case "Manager":
        return "Manager";
      case "Tutor":
        return "Teacher";
      case "Admin Staff":
        return "Employee";
      default:
        return title; // Use the title itself as the custom value if not found
    }
  }
  function clearFilter() {
    setPersonName([]);
    setSelectGrades([]);
    setSelectLanguages([]);
    setSelectProgram([]);
    setSelectSchool([]);
    setSelectSubjects([]);
    mutate({ role: userByRoleShow, active: activeShow });
  }
  if (isPending) return <LoaderSpinner />;
  // console.log(agencyData, programData, newRole, languageGet, gradeData);
  return (
    <div className="container">
      {/* {loadingActive == true ? (
        <div
          className="d-flex justify-content-center align-items-center "
          style={{
            height: "100vh",
            width: "70%",
            position: "fixed",
            zIndex: "20000",
          }}
        >
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        </div>
      ) : (
        <div></div>
      )} */}
      {status ? (
        <NewUser
          newRole={newRole}
          agencyData={agencyData}
          programData={programData}
          schoolsData={schoolsData}
          gradeData={gradeData}
          subjectData={subjectData}
          timeZoneGet={timeZoneGet}
          languageGet={languageGet}
          close={handleCloseModel}
        />
      ) : (
        <div>
          <div className="row user-box-1">
            <div className="col-lg-12 col-12 border  d-flex justify-content-center  justify-content-between align-items-center pt-3 pb-3">
              <h4 className="user-h4 mt-2">USERS</h4>
              <div>
                {userByRoleShow == "Teacher" && (
                  <CSVLink
                    className="btn btn-dangerexcel mt-1"
                    id="Abble"
                    {...csvLink}
                  >
                    Export to CSV
                  </CSVLink>
                )}
              </div>
            </div>
          </div>
          <div
            className="row d-flex justify-content-center justify-content-between pt-3 pb-3 align-items-center ps-md-3 pe-md-3"
            style={{ background: "#c7d7df" }}
          >
            <div className="col-lg-6 text-md-start d-flex align-items-center ">
              <button
                className="btn btn-primaryadd me-md-3 mt-2"
                onClick={changeAgenciews}
              >
                <i className="fa-solid fa-plus"></i> Add New User
              </button>
              {/* <div>
              <button
                className="btn btn-infos me-md-3 mt-2 text-white"
                onClick={() => setModal(true)}
              >
                <i className="fa fa-upload px-1" aria-hidden="true" />
                Upload CSV File
              </button>
            </div> */}
            </div>

            <div className="col-lg-6  ">
              <div className="row  d-flex justify-content-lg-end ">
                <div className="col-lg-5 mt-2">
                  <FormControl className="select-width" size="small">
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      value={userByRoleShow}
                      onChange={userByNameShowHandle}
                      MenuProps={MenuProps}
                      className="text-start"
                    >
                      {newRole.map((val) => (
                        <MenuItem key={val._id} value={getRoleValue(val.title)}>
                          {val.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-5 mt-2">
                  <FormControl className="select-width" size="small">
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      value={activeShow}
                      onChange={activeHandle}
                      MenuProps={MenuProps}
                      className="text-start"
                    >
                      <MenuItem value={"true"}>Active</MenuItem>
                      <MenuItem value={"false"}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-2">
              <FormControl className="select-width" size="small">
                <InputLabel
                  id="demo-multiple-name-label"
                  className="InputLabel-text"
                >
                  Select Agency
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="Select Agency" />}
                  MenuProps={MenuProps}
                  className="text-start"
                >
                  {agencyData.length > 0 ? (
                    agencyData.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.title}
                        // style={getStyles(name, personName, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 mt-2">
              <FormControl className="select-width" size="small">
                <InputLabel id="demo-multiple-name-label-one">
                  Select Language
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label-one"
                  id="demo-multiple-name-one"
                  multiple
                  value={selectLanguages}
                  onChange={handleChangeOne}
                  input={<OutlinedInput label=" Select Language" />}
                  MenuProps={MenuProps}
                  className="text-start"
                >
                  {languageGet.length > 0 ? (
                    languageGet.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.language}
                        // style={getStylesone(name, selectLanguages, theme)}
                      >
                        {name.language}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 mt-2">
              <FormControl className="select-width" size="small">
                <InputLabel id="demo-multiple-name-label-two">
                  Select Program
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label-two"
                  id="demo-multiple-name-two"
                  multiple
                  value={selectPrograms}
                  onChange={handleChangeTwo}
                  input={<OutlinedInput label=" Select Program" />}
                  MenuProps={MenuProps}
                  className="text-start"
                >
                  {programData.length > 0 ? (
                    programData.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.title}
                        // style={getStylesTwo(name, selectPrograms, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 mt-2">
              <FormControl className="select-width" size="small">
                <InputLabel id="demo-multiple-name-label-two">
                  Select School
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label-two"
                  id="demo-multiple-name-two"
                  multiple
                  value={selectSchools}
                  onChange={handleChangeThree}
                  input={<OutlinedInput label=" Select School" />}
                  MenuProps={MenuProps}
                  className="text-start"
                >
                  {schoolsData.length > 0 ? (
                    schoolsData.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.title}
                        // style={getStylesThree(name, selectSchools, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 mt-2">
              <FormControl className="select-width" size="small">
                <InputLabel id="demo-multiple-name-label-two">
                  Select Grade
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label-two"
                  id="demo-multiple-name-two"
                  multiple
                  value={selectGrades}
                  onChange={handleChangeFour}
                  input={<OutlinedInput label=" Select Grade" />}
                  MenuProps={MenuProps}
                  className="text-start"
                >
                  {gradeData.length > 0 ? (
                    gradeData.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.title}
                        // style={getStylesFour(name, selectGrades, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 mt-2">
              <FormControl className="select-width" size="small">
                <InputLabel id="demo-multiple-name-label-two">
                  Select Subject
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label-two"
                  id="demo-multiple-name-two"
                  multiple
                  value={selectSubjects}
                  onChange={handleChangeFive}
                  input={<OutlinedInput label=" Select Subject" />}
                  MenuProps={MenuProps}
                  className="text-start"
                >
                  {subjectData.length > 0 ? (
                    subjectData.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.title}
                        // style={getStylesFive(name, selectSubjects, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-11 mt-3 mb-3">
              <div className="d-grid gap-2">
                <button
                  className="btn btn-infos"
                  size="lg"
                  style={{ color: "white" }}
                  onClick={filterValue}
                >
                  <i className="fa-solid fa-magnifying-glass"></i> Search
                </button>
              </div>
            </div>
            <div className="col-lg-4 col-11 mt-3 mb-3">
              <div className="d-grid gap-2">
                <button
                  className="btn btn-infos"
                  size="lg"
                  style={{ color: "white" }}
                  onClick={clearFilter}
                >
                  Clear Search
                </button>
              </div>
            </div>
          </div>
          <div
            className="row "
            style={{
              background: "#c7d7df",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <span className="text-start">Total Users: {usersData.length}</span>

            <div className="col-lg-12 pb-4">
              {tableLoading == true ? (
                <div
                  className="d-flex justify-content-center align-items-center mt-4 "
                  style={{ width: "75%", position: "fixed", zIndex: "20000" }}
                >
                  <div className="spinner"></div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 responsive-table">
                    {/* <DataTable
                  columns={columns}
                  data={usersData}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="600px"
                  highlightOnHover
                  subHeader
                  theme="solarized"
                  striped
                  subHeaderComponent={
                    <input
                      type="text"
                      placeholder="Search User"
                      className="form-control"
                      style={{ width: "28%" }}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  }
                  responsive={true}
                /> */}
                    <DataTable
                      columns={columns}
                      data={search.trim() === "" ? usersData : filteredUsers} // 👈 Basis for showing data or "No Data Found"
                      pagination
                      fixedHeader
                      fixedHeaderScrollHeight="600px"
                      highlightOnHover
                      subHeader
                      theme="solarized"
                      striped
                      subHeaderComponent={
                        <input
                          type="text"
                          placeholder="Search User"
                          className="form-control"
                          style={{ width: "28%" }}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      }
                      responsive={true}
                    />
                  </div>
                </div>
                // <>
                //   {activeShow == "true" ? (
                //     <div className="responsive-table">
                //       <DataTable
                //         columns={columns}
                //         data={filterSearchInactive}
                //         pagination
                //         fixedHeader
                //         fixedHeaderScrollHeight="600px"
                //         highlightOnHover
                //         subHeader
                //         theme="solarized"
                //         striped
                //         subHeaderComponent={
                //           <input
                //             type="text"
                //             placeholder="Search User"
                //             className="form-control"
                //             style={{ width: "28%" }}
                //             value={searchInactive}
                //             onChange={(e) => setSearchInactive(e.target.value)}
                //           />
                //         }
                //         // responsive={true}
                //       />
                //     </div>
                //   ) : (
                //     <div className="row">
                //       <div className="col-12 responsive-table">
                //         <DataTable
                //           columns={columns}
                //           data={usersData}
                //           pagination
                //           fixedHeader
                //           fixedHeaderScrollHeight="600px"
                //           highlightOnHover
                //           subHeader
                //           theme="solarized"
                //           striped
                //           subHeaderComponent={
                //             <input
                //               type="text"
                //               placeholder="Search User"
                //               className="form-control"
                //               style={{ width: "28%" }}
                //               value={search}
                //               onChange={(e) => setSearch(e.target.value)}
                //             />
                //           }
                //           responsive={true}
                //         />
                //       </div>
                //     </div>
                //     // <>
                //     // </>
                //   )}
                // </>
              )}
            </div>
          </div>
        </div>
      )}

      {modalShow ? (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete User. This will be remove
            Permanently !
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={handleDataDelete}>
              {deleteLoading == true ? (
                <Spinner animation="border" />
              ) : (
                <span>Delete</span>
              )}
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
      {/* {modal && (
        <Modal
          show={modal}
          onHide={() => {
            setUserFile(null);
            setModal(false);
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload CSV File
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input type="file" accept=".csv" onChange={handleFileChange} />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-infos text-white" onClick={handleUpload}>
              {deleteLoading ? (
                <Spinner animation="border" />
              ) : (
                <span>Confirm</span>
              )}
            </button>
          </Modal.Footer>
        </Modal>
      )} */}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
export default User;
