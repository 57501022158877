import { React, useEffect, useState } from "react";
import TimePicker from "@semcore/ui/time-picker";
import Spinner from "react-bootstrap/Spinner";
import "./User.css";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Input } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BACKEND_URI } from "../../config/config";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import toast, { Toaster } from "react-hot-toast";
import { toSeconds } from "../../Convertor";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const NewUser = ({
  newRole,
  agencyData,
  programData,
  schoolsData,
  gradeData,
  subjectData,
  timeZoneGet,
  languageGet,
  close,
}) => {
  const [personNameEnter, setPersonNameEnter] = useState([]);
  const [selectLanguagesEnter, setSelectLanguagesEnter] = useState([]);
  const [selectProgramsEnter, setSelectProgramEnter] = useState([]);
  const [selectSchoolsEnter, setSelectSchoolEnter] = useState([]);
  const [selectGradesEnter, setSelectGradesEnter] = useState([]);
  const [selectSubjectsEnter, setSelectSubjectsEnter] = useState([]);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [reTypePassword, setReTypePassword] = useState("");
  const [showreTypePassword, setShowreTypePassword] = useState(false);
  const [mondayStartTimes, setMondayStartTime] = useState("");
  const [mondayEndTimes, setMondayEndTime] = useState("");
  const [tuesdayStartTimes, setTuesdayStartTime] = useState("");
  const [tuesdayEndTimes, setTuesdayEndTime] = useState("");
  const [wednesdayStartTimes, setWednesdayStartTime] = useState("");
  const [wednesdayEndTimes, setWednesdayEndTime] = useState("");
  const [thursdayStartTimes, setThursdayStartTime] = useState("");
  const [thursdayEndTimes, setThursdayEndTime] = useState("");
  const [fridayStartTimes, setFridayStartTime] = useState("");
  const [fridayEndTimes, setFridayEndTime] = useState("");
  const [saturdayStartTimes, setSaturdayStartTime] = useState("");
  const [saturdayEndTimes, setSaturdayEndTime] = useState("");
  const [sundayStartTimes, setSundayStartTime] = useState("");
  const [sundayEndTimes, setSundayEndTime] = useState("");
  const [lodaing, setloading] = useState(false);
  const [age, setAge] = useState("");
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState([]);
  const [roleOpen, setRoleOpen] = useState(false);
  const [timeZone, setTimeZone] = useState([]);
  const [timeZoneOpen, setTimeZoneOpen] = useState(false);
  const [gender, setGender] = useState("");
  const [genderOpen, setGenderOpen] = useState(false);
  const [consortiumId, setConsortiumId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEMail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [active, setActive] = useState(true);
  const [activeStatus, setActiveStatus] = useState(true);
  const theme = useTheme();
  const token = localStorage.getItem("jwtToken");
  const [imageSource, setImageSource] = useState();

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const userDataSave = async () => {
    // let data = new Date();
    // let dateTime = data.toDateString();
    // let stuent_data = JSON.parse(localStorage.getItem("studentNest"));
    // let First_Name = stuent_data.firstName;
    // let Last_Name = stuent_data.lastName;
    // let status = `${firstName} ${lastName} has been Created by ${First_Name} ${Last_Name}`;
    try {
      if (
        !firstName ||
        !lastName ||
        !email ||
        !mobileNumber ||
        !address ||
        !password
      ) {
        setDataError(true);
        return false;
      }
      if (password !== reTypePassword) {
        setPasswordError(true);
        return false;
      }
      setloading(true);
      console.log(sundayStartTimes, "---", sundayEndTimes);
      let mondayStartTime = toSeconds(mondayStartTimes) ?? null;
      let mondayEndTime = toSeconds(mondayEndTimes) ?? null;
      let tuesdayStartTime = toSeconds(tuesdayStartTimes) ?? null;
      let tuesdayEndTime = toSeconds(tuesdayEndTimes) ?? null;
      let wednesdayStartTime = toSeconds(wednesdayStartTimes) ?? null;
      let wednesdayEndTime = toSeconds(wednesdayEndTimes) ?? null;
      let thursdayStartTime = toSeconds(thursdayStartTimes) ?? null;
      let thursdayEndTime = toSeconds(thursdayEndTimes) ?? null;
      let fridayStartTime = toSeconds(fridayStartTimes) ?? null;
      let fridayEndTime = toSeconds(fridayEndTimes) ?? null;
      let saturdayStartTime = toSeconds(saturdayStartTimes) ?? null;
      let saturdayEndTime = toSeconds(saturdayEndTimes) ?? null;
      let sundayStartTime = toSeconds(sundayStartTimes) ?? null;
      let sundayEndTime = toSeconds(sundayEndTimes) ?? null;

      console.log(sundayStartTimes, "---", sundayEndTime);

      const formData = new FormData();
      formData.append("image", imageSource);
      const imageName = await axios.post(`${BACKEND_URI}/profile`, formData, {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      });

      await axios
        .post(
          `${BACKEND_URI}/User_Data`,
          {
            role,
            timeZone,
            personNameEnter,
            selectProgramsEnter,
            selectSchoolsEnter,
            selectGradesEnter,
            selectSubjectsEnter,
            selectLanguagesEnter,
            consortiumId,
            gender,
            firstName,
            lastName,
            email,
            mobileNumber,
            address,
            image: imageName.data.filename,
            password,
            active,
            activeStatus,
            mondayStartTime,
            mondayEndTime,
            tuesdayStartTime,
            tuesdayEndTime,
            wednesdayStartTime,
            wednesdayEndTime,
            thursdayStartTime,
            thursdayEndTime,
            fridayStartTime,
            fridayEndTime,
            saturdayStartTime,
            saturdayEndTime,
            sundayStartTime,
            sundayEndTime,
          },
          { headers }
        )
        .then(async (userRes) => {
          // console.log(userRes.data);
          if (userRes.data.result == "Duplicate") {
            toast.error(`Email Already Used ! Please Use Another Email`);
            setloading(false);
          } else {
            // if (userRes) {
            //   await axios
            //     .post(`${BACKEND_URI}/activity_Log`, {
            //       First_Name,
            //       Last_Name,
            //       dateTime,
            //       status,
            //     })
            //     .then((resactivty) => {});
            // }
            setRole([]);
            setTimeZone([]);
            setPersonNameEnter([]);
            setSelectProgramEnter([]);
            setSelectSchoolEnter([]);
            setSelectGradesEnter([]);
            setSelectSubjectsEnter([]);
            setSelectLanguagesEnter([]);
            setGender("");
            setConsortiumId("");
            setFirstName("");
            setLastName("");
            setEMail("");
            setMobileNumber("");
            setAddress("");
            setPassword("");
            setMondayStartTime("");
            setMondayEndTime("");
            setTuesdayStartTime("");
            setTuesdayEndTime("");
            setWednesdayStartTime("");
            setThursdayStartTime("");
            setReTypePassword("");
            setThursdayEndTime("");
            setFridayStartTime("");
            setFridayEndTime("");
            setSaturdayStartTime("");
            setSaturdayEndTime("");
            setSundayStartTime("");
            setSundayEndTime("");
            setImageSource("");
            toast.success("Data Submit successfully");
            setloading(false);
          }
        });
    } catch (e) {
      console.log("e", e);
      setloading(false);
      toast.error("Something Went Wrong! Please Try Again");
    }
  };
  const handleChangeTimeZone = (event) => {
    setTimeZone(event.target.value);
  };
  const handleCloseTimeZone = () => {
    setTimeZoneOpen(false);
  };
  const handleOpenTimeZone = () => {
    setTimeZoneOpen(true);
  };
  const handleImageUpload = (e) => {
    let filess = e.target.files[0];
    // console.log(filess);
    setImageSource(filess);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const handleClickTypeShowPassword = () => {
    setShowreTypePassword(!showreTypePassword);
  };
  const handleChangeTypePassword = (e) => {
    setReTypePassword(e.target.value);
  };
  const handleChangeRole = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 2) {
      setRole(typeof value === "string" ? value.split(",") : value);
    } else {
      setRole([]);
    }
  };
  const handleChangeLanguage = (event) => {
    const {
      target: { value },
    } = event;
    console.log("language", value);

    setSelectLanguagesEnter(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeAgency = (event) => {
    const {
      target: { value },
    } = event;
    console.log("agency", value);

    setPersonNameEnter(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangePrograms = (event) => {
    const {
      target: { value },
    } = event;
    console.log("program", value);

    setSelectProgramEnter(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSchool = (event) => {
    const {
      target: { value },
    } = event;
    console.log("school", value);

    setSelectSchoolEnter(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeGrade = (event) => {
    const {
      target: { value },
    } = event;
    console.log("agency", value);

    setSelectGradesEnter(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSubjects = (event) => {
    const {
      target: { value },
    } = event;
    console.log("subject", value);

    setSelectSubjectsEnter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };
  const handleCloseGender = () => {
    setGenderOpen(false);
  };
  const handleOpenGender = () => {
    setGenderOpen(true);
  };

  function getStylesAgency(name, personNameEnter, theme) {
    return {
      fontWeight:
        personNameEnter.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const changeAgenciews = () => {
    // setStatus(!status);
    setPassword("");
  };
  function getRoleValue(title) {
    switch (title) {
      case "Manager":
        return "Manager";
      case "Tutor":
        return "Teacher";
      case "Admin Staff":
        return "Employee";
      default:
        return title; // Use the title itself as the custom value if not found
    }
  }
  return (
    <div>
      <div className="row user-box-1">
        <div className="col-lg-12 col-12  d-flex justify-content-center  justify-content-between align-items-center pt-3 pb-3">
          <h4 className="user-h4 mt-2">NEW USER</h4>
        </div>
      </div>
      <div
        className="row d-flex justify-content-center "
        style={{
          background: "#c7d7df",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <div className="col-lg-10 ms-3  mt-2 ">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Role
              </label>
            </div>
            <FormControl className="select-width-demo" size="small">
              <InputLabel id="demo-controlled-open-select-label">
                Role
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={roleOpen}
                onClose={() => setRoleOpen(false)}
                onOpen={() => setRoleOpen(true)}
                multiple
                value={role}
                label="Role"
                className="text-start"
                onChange={handleChangeRole}
              >
                {newRole.map((val) => (
                  <MenuItem key={val._id} value={getRoleValue(val.title)}>
                    {val.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {role == "Manager" ? (
          <div className="row d-flex justify-content-center ">
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Language
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Language
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectLanguagesEnter}
                    onChange={handleChangeLanguage}
                    input={<OutlinedInput label="Select Language" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {languageGet.length > 0 ? (
                      languageGet.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.language}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.language}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              {active ? (
                <div className="row  d-flex justify-content-center">
                  <div className="col-lg-9 box-col ">
                    <h6>Monday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />

                      <TimePicker
                        onChange={(value) =>
                          setMondayStartTime(
                            value
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      <TimePicker
                        onChange={(value) =>
                          setMondayEndTime(
                            value
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Tuesday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={tuesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setTuesdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={tuesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setTuesdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Wednesday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={wednesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setWednesdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={wednesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setWednesdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Thursday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={thursdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setThursdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={thursdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setThursdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Friday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={fridayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setFridayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={fridayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setFridayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Saturday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={saturdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSaturdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={saturdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSaturdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Sunday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={sundayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSundayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={sundayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSundayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : role == "Employee" ? (
          <div className="row d-flex justify-content-center ">
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Language
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Language
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectLanguagesEnter}
                    onChange={handleChangeLanguage}
                    input={<OutlinedInput label="Select Language" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {languageGet.length > 0 ? (
                      languageGet.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.language}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.language}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        ) : //  role == "Student" ? (
        //   <div className="row d-flex justify-content-center">
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Time Zone
        //           </label>
        //         </div>
        //         <FormControl className="select-width-demo" size="small">
        //           <InputLabel id="demo-controlled-open-select-label">
        //             Time Zone
        //           </InputLabel>
        //           <Select
        //             labelId="demo-controlled-open-select-label"
        //             id="demo-controlled-open-select"
        //             open={timeZoneOpen}
        //             onClose={handleCloseTimeZone}
        //             onOpen={handleOpenTimeZone}
        //             value={timeZone}
        //             label="Time Zone"
        //             className="text-start"
        //             onChange={handleChangeTimeZone}
        //           >
        //             {timeZoneGet.length > 0 ? (
        //               timeZoneGet.map((items) => {
        //                 return (
        //                   <MenuItem value={items.timezone} key={items._id}>
        //                     {items.timezone}
        //                   </MenuItem>
        //                 );
        //               })
        //             ) : (
        //               <MenuItem>No Data</MenuItem>
        //             )}
        //           </Select>
        //         </FormControl>
        //       </div>
        //     </div>
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Agency
        //           </label>
        //         </div>
        //         <FormControl className="select-width-demo" size="small">
        //           <InputLabel id="demo-multiple-name-label">
        //             Select Agency
        //           </InputLabel>
        //           <Select
        //             labelId="demo-multiple-name-label"
        //             id="demo-multiple-name"
        //             multiple
        //             value={personNameEnter}
        //             onChange={(e) => handleChangeAgency(e)}
        //             input={<OutlinedInput label="Select Agency" />}
        //             MenuProps={MenuProps}
        //             className="text-start"
        //           >
        //             {agencyData.length > 0 ? (
        //               agencyData.map((name) => (
        //                 <MenuItem
        //                   key={name.id}
        //                   value={name.title}
        //                   style={getStylesAgency(name, personNameEnter, theme)}
        //                 >
        //                   {name.title}
        //                 </MenuItem>
        //               ))
        //             ) : (
        //               <MenuItem>No Data</MenuItem>
        //             )}
        //           </Select>
        //         </FormControl>
        //       </div>
        //     </div>
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Programs
        //           </label>
        //         </div>
        //         <FormControl className="select-width-demo" size="small">
        //           <InputLabel id="demo-multiple-name-label">
        //             Select Programs
        //           </InputLabel>
        //           <Select
        //             labelId="demo-multiple-name-label"
        //             id="demo-multiple-name"
        //             multiple
        //             value={selectProgramsEnter}
        //             onChange={(e) => handleChangePrograms(e)}
        //             input={<OutlinedInput label="Select Programs" />}
        //             MenuProps={MenuProps}
        //             className="text-start"
        //           >
        //             {programData.length > 0 ? (
        //               programData.map((name) => (
        //                 <MenuItem
        //                   key={name.id}
        //                   value={name.title}
        //                   style={getStylesAgency(name, personNameEnter, theme)}
        //                 >
        //                   {name.title}
        //                 </MenuItem>
        //               ))
        //             ) : (
        //               <MenuItem>No Data</MenuItem>
        //             )}
        //           </Select>
        //         </FormControl>
        //       </div>
        //     </div>
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Schools
        //           </label>
        //         </div>
        //         <FormControl className="select-width-demo" size="small">
        //           <InputLabel id="demo-multiple-name-label">
        //             Select School
        //           </InputLabel>
        //           <Select
        //             labelId="demo-multiple-name-label"
        //             id="demo-multiple-name"
        //             multiple
        //             value={selectSchoolsEnter}
        //             onChange={(e) => handleChangeSchool(e)}
        //             input={<OutlinedInput label="Select School" />}
        //             MenuProps={MenuProps}
        //             className="text-start"
        //           >
        //             {schoolsData.length > 0 ? (
        //               schoolsData.map((name) => (
        //                 <MenuItem
        //                   key={name.id}
        //                   value={name.title}
        //                   style={getStylesAgency(name, personNameEnter, theme)}
        //                 >
        //                   {name.title}
        //                 </MenuItem>
        //               ))
        //             ) : (
        //               <MenuItem>No Data</MenuItem>
        //             )}
        //           </Select>
        //         </FormControl>
        //       </div>
        //     </div>
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Grade
        //           </label>
        //         </div>
        //         <FormControl className="select-width-demo" size="small">
        //           <InputLabel id="demo-multiple-name-label">
        //             Select Grade
        //           </InputLabel>
        //           <Select
        //             labelId="demo-multiple-name-label"
        //             id="demo-multiple-name"
        //             multiple
        //             value={selectGradesEnter}
        //             onChange={handleChangeGrade}
        //             input={<OutlinedInput label="Select Grade" />}
        //             MenuProps={MenuProps}
        //             className="text-start"
        //           >
        //             {gradeData.length > 0 ? (
        //               gradeData.map((name) => (
        //                 <MenuItem
        //                   key={name.id}
        //                   value={name.title}
        //                   style={getStylesAgency(name, personNameEnter, theme)}
        //                 >
        //                   {name.title}
        //                 </MenuItem>
        //               ))
        //             ) : (
        //               <MenuItem>No Data</MenuItem>
        //             )}
        //           </Select>
        //         </FormControl>
        //       </div>
        //     </div>
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Subjects
        //           </label>
        //         </div>
        //         <FormControl className="select-width-demo" size="small">
        //           <InputLabel id="demo-multiple-name-label">
        //             Select Subjects
        //           </InputLabel>
        //           <Select
        //             labelId="demo-multiple-name-label"
        //             id="demo-multiple-name"
        //             multiple
        //             value={selectSubjectsEnter}
        //             onChange={handleChangeSubjects}
        //             input={<OutlinedInput label="Select Subjects" />}
        //             MenuProps={MenuProps}
        //             className="text-start"
        //           >
        //             {subjectData.length > 0 ? (
        //               subjectData.map((name) => (
        //                 <MenuItem
        //                   key={name.id}
        //                   value={name.title}
        //                   style={getStylesAgency(name, personNameEnter, theme)}
        //                 >
        //                   {name.title}
        //                 </MenuItem>
        //               ))
        //             ) : (
        //               <MenuItem>No Data</MenuItem>
        //             )}
        //           </Select>
        //         </FormControl>
        //       </div>
        //     </div>
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Language
        //           </label>
        //         </div>
        //         <FormControl className="select-width-demo" size="small">
        //           <InputLabel id="demo-multiple-name-label">
        //             Select Language
        //           </InputLabel>
        //           <Select
        //             labelId="demo-multiple-name-label"
        //             id="demo-multiple-name"
        //             multiple
        //             value={selectLanguagesEnter}
        //             onChange={handleChangeLanguage}
        //             input={<OutlinedInput label="Select Language" />}
        //             MenuProps={MenuProps}
        //             className="text-start"
        //           >
        //             {languageGet.length > 0 ? (
        //               languageGet.map((name) => (
        //                 <MenuItem
        //                   key={name.id}
        //                   value={name.language}
        //                   style={getStylesAgency(name, personNameEnter, theme)}
        //                 >
        //                   {name.language}
        //                 </MenuItem>
        //               ))
        //             ) : (
        //               <MenuItem>No Data</MenuItem>
        //             )}
        //           </Select>
        //         </FormControl>
        //       </div>
        //     </div>
        //     <div className="col-lg-10  ">
        //       <div className="mb-3 d-flex align-items-center">
        //         <div className="col-md-2 text-start ">
        //           <label
        //             htmlFor="exampleFormControlInput1"
        //             className="form-label mt-2"
        //           >
        //             Consortium ID
        //           </label>
        //         </div>
        //         <TextField
        //           size="small"
        //           id="outlined-basic"
        //           label="Consortium ID"
        //           variant="outlined"
        //           className="select-width-demo"
        //           value={consortiumId}
        //           onChange={(e) => setConsortiumId(e.target.value)}
        //         />
        //       </div>
        //     </div>
        //   </div>
        // ) :
        role == "Teacher" ? (
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Time Zone
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-controlled-open-select-label">
                    Time Zone
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={timeZoneOpen}
                    onClose={handleCloseTimeZone}
                    onOpen={handleOpenTimeZone}
                    value={timeZone}
                    label="Time Zone"
                    className="text-start"
                    onChange={handleChangeTimeZone}
                  >
                    {timeZoneGet.length > 0 ? (
                      timeZoneGet.map((items) => {
                        return (
                          <MenuItem value={items.timezone} key={items._id}>
                            {items.timezone}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Agency
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Agency
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={personNameEnter}
                    onChange={(e) => handleChangeAgency(e)}
                    input={<OutlinedInput label="Select Agency" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {agencyData.length > 0 ? (
                      agencyData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Programs
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Programs
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectProgramsEnter}
                    onChange={(e) => handleChangePrograms(e)}
                    input={<OutlinedInput label="Select Programs" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {programData.length > 0 ? (
                      programData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Schools
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select School
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectSchoolsEnter}
                    onChange={(e) => handleChangeSchool(e)}
                    input={<OutlinedInput label="Select School" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {schoolsData.length > 0 ? (
                      schoolsData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Grade
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Grade
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectGradesEnter}
                    onChange={handleChangeGrade}
                    input={<OutlinedInput label="Select Grade" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {gradeData.length > 0 ? (
                      gradeData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Subjects
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Subjects
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectSubjectsEnter}
                    onChange={handleChangeSubjects}
                    input={<OutlinedInput label="Select Subjects" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {subjectData.length > 0 ? (
                      subjectData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Language
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Language
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectLanguagesEnter}
                    onChange={handleChangeLanguage}
                    input={<OutlinedInput label="Select Language" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {languageGet.length > 0 ? (
                      languageGet.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.language}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.language}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* <div className="col-lg-10  ">

              {active ? (
                <div className="row  d-flex justify-content-center">
                  <div className="col-lg-9 box-col ">
                    <h6>Monday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={mondayStartTimes}
                        eachInputDropdown manuallyDisplayDropdown

                        onChange={(dateString) => setMondayStartTime(dateString)} />
                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={mondayEndTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setMondayEndTime(dateString)} />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Tuesday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={tuesdayStartTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setTuesdayStartTime(dateString)} />
                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={tuesdayEndTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setTuesdayEndTime(dateString)} />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Wednesday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={wednesdayStartTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setWednesdayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={wednesdayEndTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setWednesdayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Thursday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={thursdayStartTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setThursdayStartTime(dateString)} />
                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={thursdayEndTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setThursdayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Friday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={fridayStartTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setFridayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={fridayEndTimes} manuallyDisplayDropdown eachInputDropdown onChange={(dateString) => setFridayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Saturday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={saturdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={saturdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Sunday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={sundayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={sundayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayEndTime(dateString)} />

                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div> */}
            <div className="col-lg-10  ">
              {active ? (
                <div className="row  d-flex justify-content-center">
                  <div className="col-lg-9 box-col ">
                    <h6>Monday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={mondayStartTimes}
                        eachInputDropdown manuallyDisplayDropdown
                        onChange={(dateString) => setMondayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setMondayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={mondayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setMondayEndTime(dateString)} />
                       */}
                      <TimePicker
                        onChange={(e) =>
                          setMondayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Tuesday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={tuesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setTuesdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={tuesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setTuesdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Wednesday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={wednesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setWednesdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={wednesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setWednesdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Thursday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={thursdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setThursdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={thursdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setThursdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Friday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={fridayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setFridayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={fridayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setFridayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Saturday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={saturdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSaturdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={saturdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSaturdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Sunday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={sundayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSundayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={sundayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSundayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (role.includes("Teacher") && role.includes("Employee")) ||
          (role.includes("Teacher") && role.includes("Manager")) ? (
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Time Zone
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-controlled-open-select-label">
                    Time Zone
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={timeZoneOpen}
                    onClose={handleCloseTimeZone}
                    onOpen={handleOpenTimeZone}
                    value={timeZone}
                    label="Time Zone"
                    className="text-start"
                    onChange={handleChangeTimeZone}
                  >
                    {timeZoneGet.length > 0 ? (
                      timeZoneGet.map((items) => {
                        return (
                          <MenuItem value={items.timezone} key={items._id}>
                            {items.timezone}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Agency
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Agency
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={personNameEnter}
                    onChange={(e) => handleChangeAgency(e)}
                    input={<OutlinedInput label="Select Agency" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {agencyData.length > 0 ? (
                      agencyData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Programs
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Programs
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectProgramsEnter}
                    onChange={(e) => handleChangePrograms(e)}
                    input={<OutlinedInput label="Select Programs" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {programData.length > 0 ? (
                      programData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Schools
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select School
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectSchoolsEnter}
                    onChange={(e) => handleChangeSchool(e)}
                    input={<OutlinedInput label="Select School" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {schoolsData.length > 0 ? (
                      schoolsData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Grade
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Grade
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectGradesEnter}
                    onChange={handleChangeGrade}
                    input={<OutlinedInput label="Select Grade" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {gradeData.length > 0 ? (
                      gradeData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Subjects
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Subjects
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectSubjectsEnter}
                    onChange={handleChangeSubjects}
                    input={<OutlinedInput label="Select Subjects" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {subjectData.length > 0 ? (
                      subjectData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Language
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Language
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectLanguagesEnter}
                    onChange={handleChangeLanguage}
                    input={<OutlinedInput label="Select Language" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {languageGet.length > 0 ? (
                      languageGet.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.language}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.language}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* <div className="col-lg-10  ">

              {active ? (
                <div className="row  d-flex justify-content-center">
                  <div className="col-lg-9 box-col ">
                    <h6>Monday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={mondayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setMondayStartTime(dateString)} />
                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={mondayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setMondayEndTime(dateString)} />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Tuesday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={tuesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayStartTime(dateString)} />
                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={tuesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayEndTime(dateString)} />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Wednesday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={wednesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={wednesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Thursday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={thursdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayStartTime(dateString)} />
                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={thursdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Friday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={fridayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={fridayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Saturday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={saturdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={saturdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayEndTime(dateString)} />

                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Sunday</h6>
                    <div className="text-start">
                      <label>Start Time</label>
                      <br />
                      <TimeInput value={sundayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayStartTime(dateString)} />

                    </div>
                    <div className="text-start">
                      <label>End Time</label>
                      <br />
                      <TimeInput value={sundayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayEndTime(dateString)} />

                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div> */}
            <div className="col-lg-10  ">
              {active ? (
                <div className="row  d-flex justify-content-center">
                  <div className="col-lg-9 box-col ">
                    <h6>Monday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={mondayStartTimes}
                        eachInputDropdown manuallyDisplayDropdown
                        onChange={(dateString) => setMondayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setMondayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={mondayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setMondayEndTime(dateString)} />
                       */}
                      <TimePicker
                        onChange={(e) =>
                          setMondayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Tuesday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={tuesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setTuesdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={tuesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setTuesdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setTuesdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Wednesday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={wednesdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setWednesdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={wednesdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setWednesdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setWednesdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Thursday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={thursdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setThursdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={thursdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setThursdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setThursdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Friday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={fridayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setFridayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={fridayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setFridayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setFridayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Saturday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={saturdayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSaturdayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={saturdayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSaturdayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSaturdayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 box-col ">
                    <h6>Sunday</h6>
                    <div className="time-text">
                      <label>Start Time</label>
                      <br />
                      {/* <TimeInput value={sundayStartTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayStartTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSundayStartTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                    <div className="time-text">
                      <label>End Time</label>
                      <br />
                      {/* <TimeInput value={sundayEndTimes} eachInputDropdown manuallyDisplayDropdown onChange={(dateString) => setSundayEndTime(dateString)} /> */}
                      <TimePicker
                        onChange={(e) =>
                          setSundayEndTime(
                            e
                              .split(":")
                              .map((part) => part.padStart(2, "0"))
                              .join(":")
                          )
                        }
                        size="l"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Time Zone
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-controlled-open-select-label">
                    Time Zone
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={timeZoneOpen}
                    onClose={handleCloseTimeZone}
                    onOpen={handleOpenTimeZone}
                    value={timeZone}
                    label="Time Zone"
                    className="text-start"
                    onChange={handleChangeTimeZone}
                  >
                    {timeZoneGet.length > 0 ? (
                      timeZoneGet.map((items) => {
                        return (
                          <MenuItem value={items.timezone} key={items._id}>
                            {items.timezone}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Agency
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Agency
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={personNameEnter}
                    onChange={(e) => handleChangeAgency(e)}
                    input={<OutlinedInput label="Select Agency" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {agencyData.length > 0 ? (
                      agencyData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Programs
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Programs
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectProgramsEnter}
                    onChange={(e) => handleChangePrograms(e)}
                    input={<OutlinedInput label="Select Programs" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {programData.length > 0 ? (
                      programData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Schools
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select School
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectSchoolsEnter}
                    onChange={(e) => handleChangeSchool(e)}
                    input={<OutlinedInput label="Select School" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {schoolsData.length > 0 ? (
                      schoolsData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Grade
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Grade
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectGradesEnter}
                    onChange={handleChangeGrade}
                    input={<OutlinedInput label="Select Grade" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {gradeData.length > 0 ? (
                      gradeData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Subjects
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Subjects
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectSubjectsEnter}
                    onChange={handleChangeSubjects}
                    input={<OutlinedInput label="Select Subjects" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {subjectData.length > 0 ? (
                      subjectData.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.title}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Language
                  </label>
                </div>
                <FormControl className="select-width-demo" size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Language
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectLanguagesEnter}
                    onChange={handleChangeLanguage}
                    input={<OutlinedInput label="Select Language" />}
                    MenuProps={MenuProps}
                    className="text-start"
                  >
                    {languageGet.length > 0 ? (
                      languageGet.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.language}
                          style={getStylesAgency(name, personNameEnter, theme)}
                        >
                          {name.language}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-10  ">
              <div className="mb-3 d-flex align-items-center">
                <div className="col-md-2 text-start ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mt-2"
                  >
                    Consortium ID
                  </label>
                </div>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Consortium ID"
                  variant="outlined"
                  className="select-width-demo"
                  value={consortiumId}
                  onChange={(e) => setConsortiumId(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="col-lg-10  ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                First Name
              </label>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                size="small"
                className="select-width-demo"
                value={firstName}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\s/g, "");
                  setFirstName(newValue);
                }}
              />
              {dataError && !firstName && (
                <div className="text-start" style={{ color: "red" }}>
                  Please Enter First Name
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-10  ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Last Name
              </label>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                className="select-width-demo"
                size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {dataError && !lastName && (
                <div className="text-start" style={{ color: "red" }}>
                  Please Enter Last Name
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-10  ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Email
              </label>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                className="select-width-demo"
                size="small"
                value={email}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\s/g, "");
                  setEMail(newValue);
                }}
              />
              {dataError && !email && (
                <div className="text-start" style={{ color: "red" }}>
                  Please Enter Email
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-10  ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Mobile
              </label>
            </div>
            <div>
              <TextField
                id="outlined-size-small"
                label="Mobile No."
                // label="Number"
                type="number"
                placeholder="(100)-000-00000"
                variant="outlined"
                size="small"
                className="select-width-demo"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              {dataError && !mobileNumber && (
                <div className="text-start" style={{ color: "red" }}>
                  Please Enter mobile Number
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-10  ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Address
              </label>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                className="select-width-demo"
                size="small"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              {dataError && !address && (
                <div className="text-start" style={{ color: "red" }}>
                  Please Enter Address
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-10 ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Gender
              </label>
            </div>
            <FormControl className="select-width-demo" size="small">
              <InputLabel id="demo-controlled-open-select-label">
                Gender
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={genderOpen}
                onClose={handleCloseGender}
                onOpen={handleOpenGender}
                value={gender}
                label="Gender"
                className="text-start"
                onChange={handleChangeGender}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-lg-10 ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Profile Image
              </label>
            </div>
            <div className="form">
              <FormControl
                className="select-width-demo"
                size="small"
                style={{ border: "1px solid gray", borderRadius: "4px" }}
              >
                <input
                  type="file"
                  name="file"
                  onChange={handleImageUpload}
                  style={{ cursor: "pointer", padding: "1px" }}
                  required
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="col-lg-10 ms-md-3">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Password
              </label>
            </div>
            <div>
              <OutlinedInput
                size="small"
                className="select-width-demo"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleChangepassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter Your Password"
              />
              {dataError && !password && (
                <div className="text-start" style={{ color: "red" }}>
                  Please Enter Password
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-10 text-start ms-md-3">
          <div className="mb-1 d-flex align-items-center">
            <div className="col-md-2 text-start ">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mt-2"
              >
                Repeat Password
              </label>
            </div>
            <div>
              <OutlinedInput
                className="select-width-demo"
                id="outlined-adornment-password"
                type={showreTypePassword ? "text" : "password"}
                value={reTypePassword}
                size="small"
                onChange={handleChangeTypePassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickTypeShowPassword}
                      edge="end"
                    >
                      {showreTypePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter Your Password"
              />
              <br />
              {passwordError && (
                <div className="text-start" style={{ color: "red" }}>
                  Re-Type Password is not metched
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="col-md-11 mt-4 pt-3 pb-3 mb-5"
          style={{
            borderBottom: "1px solid #838383",
            borderTop: "1px solid #838383",
          }}
        >
          <button className="btn btn-save me-2" onClick={userDataSave}>
            {lodaing == true ? (
              <Spinner animation="border" />
            ) : (
              <span>Save</span>
            )}
          </button>
          <button className="btn btn-Cancel" onClick={close}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
