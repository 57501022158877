import React, { useState, useRef, useEffect } from "react";
import { BACKEND_URI } from "../../config/config";
import axios from "axios";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import DataTable, { createTheme } from "react-data-table-component";
// import Active UsersModal from "./Active UsersModal";
// import { ProgramsData } from "./formData";
// import { ProgramForm } from "./programForms";

createTheme("solarized", {
  background: {
    default: "#c0dfdf",
  },
  text: {
    primary: "#08272a",
    secondary: "#08272a",
  },
});
export default function ActiveUsers() {
  const [activeUsersData, setActiveUsersData] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState("");
  //   const [selectedActive Users, setSelectedActive Users] = useState();
  //   const [showModal, setShowModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const userRole = JSON.parse(localStorage.getItem("studentNest")).role;
  const modalRef = useRef(null);

  async function allPrograms() {
    setTableLoading(true);
    const response = await axios.get(`${BACKEND_URI}/activeUsers`);
    setTableLoading(false);
    if (response) setActiveUsersData(response?.data.data);
    else {
      console.log("no record found");
    }
  }
  useEffect(() => {
    allPrograms();
  }, []);
  const columns = [
    {
      name: (
        <span style={{ fontSize: "15px", fontWeight: "600" }}>Full Name</span>
      ),
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      grow: 2,
    },
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Email</span>,
      selector: (row) => row.email,
      sortable: true,
      grow: 2,
    },

    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Mobile</span>,
      selector: (row) => row.mobileNumber,
      sortable: true,
      grow: 1,
    },
    {
      name: (
        <span style={{ fontSize: "15px", fontWeight: "600" }}>Location</span>
      ),
      selector: (row) => row.address,
      sortable: true,
      grow: 3,
    },
    // {
    //   name: (
    //     <span style={{ fontSize: "15px", fontWeight: "600" }}>Control</span>
    //   ),
    //   cell: (row) => (
    //     <>
    //       {/* <button
    //         className="btn btn-xs btn-infoss me-2 mt-1"
    //         // style={{ paddig: "0" }}
    //         title="View"
    //       >
    //         <i
    //           className="fa-solid fa-eye"
    //           style={{ color: "white" }}
    //           onClick={() => handleActive Users(row)}
    //         ></i>
    //       </button> */}
    //       {/* <button
    //                     className="btn btn-xs me-2 mt-1"
    //                     style={{ backgroundColor: '#8B0000' }}
    //                     title="Download PDF"
    //                 >
    //                     <i
    //                         className="fa-solid fa-download"
    //                         style={{ color: "white" }}
    //                         onClick={() => handleDownload(row)}
    //                     ></i>
    //                 </button> */}
    //     </>
    //   ),
    // },
  ];
  const MenuProps = {
    PaperProps: {
      style: { maxHeight: 300 },
    },
  };
  //   console.log(activeUsersData);
  //   const handleActive Users = (data) => {
  //     setSelectedActive Users(data);
  //     setShowModal(true);
  //   };

  //   const handleChangePrograms = (event) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setSelectedPrograms(value);
  //   };
  return (
    <>
      <div className="container">
        <div className="row user-box-1">
          <div className="col-lg-12 col-12  d-flex justify-content-center  justify-content-between align-items-center pt-3 pb-3">
            <h4 className="user-h4 mt-2">Active Users</h4>
          </div>
        </div>
        <div
          className="row d-flex justify-content-center justify-content-between pt-3 pb-3 align-items-center ps-md-3 pe-md-3"
          style={{ background: "#c7d7df" }}
        >
          {/* {selectedPrograms && (
            <ProgramForm selectedPrograms={selectedPrograms} />
          )} */}
          {(userRole.includes("Admin") ||
            userRole.includes("Manager") ||
            userRole.includes("Employee")) && (
            <>
              {tableLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center mt-4 "
                  style={{ width: "75%", position: "fixed", zIndex: "20000" }}
                >
                  <div className="spinner"></div>
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={activeUsersData}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="600px"
                  highlightOnHover
                  subHeader
                  theme="solarized"
                  striped
                  // subHeaderComponent={
                  //     <input type="text" placeholder='Search User' className='form-control' style={{ width: '28%' }} value={searchInactive} onChange={(e) => setSearchInactive(e.target.value)} />
                  // }
                  responsive={true}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
