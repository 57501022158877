import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./Component/SideBar/SideBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Component/Login/Login";
import PrivateComponent from "./Component/PrivateComponent/PrivateComponent";
import NotFound from "./Component/NotFound";
import SessionExpiredPopup from "./Component/Popup/SessionPopup";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import { BACKEND_URI } from "./config/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();
function App() {
  const [state, setState] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const userData = JSON.parse(localStorage.getItem("studentNest"));

  // Axios interceptor to handle session expiration
  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          setSessionExpired(true);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, []);

  const handleSessionExpiration = async () => {
    setSessionExpired(true);
    await axios.patch(`${BACKEND_URI}/logout`, userData);
    localStorage.clear();
  };

  const { reset } = useIdleTimer({
    timeout: 15 * 60 * 1000, // 15 minutes timeout
    onIdle: handleSessionExpiration,
  });

  useEffect(() => {
    if (sessionExpired) {
      reset();
    }
  }, [sessionExpired, reset]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {sessionExpired && <div className="background-blur"></div>}
          {sessionExpired ? (
            <SessionExpiredPopup
              visible={sessionExpired}
              onClose={() => setSessionExpired(false)}
            />
          ) : (
            <Routes>
              <Route
                path="/"
                element={<Login setstate={setState} state={state} />}
              />

              {/* Protected Routes (Require Authentication) */}
              <Route element={<PrivateComponent state={state} />}>
                <Route path="/sidebar/*" element={<Sidebar />} />
              </Route>

              {/* 404 Page */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
